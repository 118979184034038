export const loginQuery = `
    mutation Login($username: String!, $password: String!, $station: Int) {
        login(username: $username, password: $password, station: $station) {
            token
            user {
                login
            }
            payload
            needPasswordChange
        }
    }
`;

export const resetPasswordQuery = `
    mutation ResetPassword($confirmPassword: String!, $login: String!, $newPassword: String!, $oldPassword: String!) {
        passwordReset(confirmPassword: $confirmPassword, login: $login, newPassword: $newPassword, password: $oldPassword) {
            passwordChanged
        }
    }
`;
